import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Title from "../components/Title/Title"
import Text from "../components/Text/Text"
import TxtImg from "../components/TxtImg/TxtImg"
import Button from "../components/Button/Button"
import Console from "../components/Console/Console"
import { graphql } from "gatsby"

function About({ data }) {
    return (
        <Layout pageTitle="About">
            <Helmet>
                <meta name="description" content="I'm an independent optometrist whose primary focus is the health of your eyes rather than selling you a new pair of glasses." />
            </Helmet>
            <section className="condense">
                <Title>
                    <h1>About me</h1>
                    <p>An optometrist whose primary focus is the health of your eyes rather than selling you a new pair of glasses.</p>
                </Title>
            </section>
            <section className="condense">
                <Text>
                    <h2>My Philosophy</h2>
                    <h3>Why a 'Consulting' Neuro-Optometrist?</h3>
                    <p>In eyecare, it's all too common for patients to feel rushed through their eye exams and be quickly given their diagnosis before being sent on their way. Communication can often be sadly lacking and there's no opportunity for questions if they aren't asked there and then.</p>
                    <p>As a <em>Consulting</em> Optometrist (historically we were called 'opticians'), my ethos is to carry out a thorough clinical eye examination and take the time to discuss my findings with you. If you think of something later that you wish you'd asked, you simply get in touch and we'll discuss it further.</p>
                    <h3>Caring for eye health, not selling glasses.</h3>
                    <p>Unfortunately, optometrists have gained a reputation as “shopticians” and I have worked in such an environment, where bonuses are given for getting more <em>customers</em> to buy glasses following their <em>eye test</em>. As a clinician rather than a salesman I knew this wasn't for me - I prefer to think in terms of <em>patients</em> and their <em>eye examinations</em>.</p>
                    <p>I don't sell glasses because I don't want my patients to question whether the advice I have given is genuine or because I'm motivated by making sales. This may seem unusual as we're all used to choosing some new frames as soon as we come out of our eye exam. The UK is actually unusual in this way; most other countries separate the clinically-focused eye exam from the retail-driven dispensing of spectacles.</p>
                </Text>
            </section>
            <section>
                <TxtImg
                    imgFirst={false}
                    split="half"
                    imgSrc={data.sidestreet}
                    imgAlt="Exterior of the practice and surrounding street scene"
                >
                    <h2>What to Expect From Your Appointment</h2>
                    <p>When coming to my practice, you won't be seen as a <em>customer</em> coming in for a <em>sight test</em> but as a <em>patient</em> coming in for an <em>eye exam</em>. This may sound like a small difference but it's an important shift in mindset. You'll be coming to me to get the health and functioning of your eyes thoroughly checked, just as you would with your teeth when visiting the dentist.</p>
                    <p>I carry out tests using clinically robust instruments and my findings are documented so that the long-term changes in your eye health can be monitored. Monitoring for changes over time can be crucial for detecting a number of conditions. This makes it important to see the same person each time whenever possible.</p>
                    <p style={{marginBottom: 'var(--space-5)'}}>When given a prescription, you can take this to an optician who sells spectacles. This way, my advice remains 100% in your best clinical interests.</p>
                    <Button type="primarySolid" to="/contact" external={false}>Book an appointment</Button>
                </TxtImg>
            </section>
            <section className="condense">
                <div style={{width: 'min(90%, 1400px)', margin: 'auto'}}>
                    <Console variant="center">
                        <h2>My Background & Expertise</h2>
                        <TxtImg
                            imgFirst={false}
                            split="narrowHalf"
                            imgSrc={data.earlyExperiences}
                            imgAlt="Christian aged 12 months"
                        >
                            <h3>Childhood experiences first introduced me to optometry.</h3>
                            <p>As a child I required squint surgery, wearing a patch and latterly spectacles. As well as greatly improving my eyesight, all of these experiences ignited a real passion and interest in eyes and optometry. This also developed a certain level of empathy for those with issues relating to their eyesight; I have experienced these first hand and can modify my testing routine accordingly as I am fully aware of the difficulties some people can face.</p>
                        </TxtImg>
                        <TxtImg
                            imgFirst={true}
                            split="narrowHalf"
                            imgSrc={data.professional}
                            imgAlt=""
                        >
                            <h3>Work background sharpened my focus on clinical excellence.</h3>
                            <p>Prior to gaining a First Class Masters in Optometry and becoming a qualified Member of the College of Optometrists, I worked at a large corporate opticians on the High Street. This was a valuable experience in sharpening my focus on delivering excellent eye care and giving patients the time they need at their eye examinations rather than completing them quickly and focusing on spectacle sales.</p>
                        </TxtImg>
                        <TxtImg
                            imgFirst={false}
                            split="narrowHalf"
                            imgSrc={data.eye}
                            imgAlt=""
                        >
                            <h3>Neuro-optometry and brain injury.</h3>
                            <p>My own personal experience with squint and lazy eye, plus the satisfaction with being able to transform somebody's visual world, has left me with a passion for vision therapy. I am particularly interested in offering visual rehabilitation to those who have suffered brain injuries (through trauma and concussion to tumours and stroke). This is a field currently not well served by optometrists or vision specialists in the UK, despite well-structured rehabilitation pathways for other senses.</p>
                            <p>In addition to being a member of the British Association of Behavioural Optometrists (BABO), I am also a member of the College of Optometrists in Visual Development (COVD) and the Neuro-Optometric Rehabilitation Association (NORA); both of which are professional bodies within the United States where vision therapy and neuro-optometry rehabilitation are better established, and have me listed on their practitioner directories. I have also attended courses in brain anatomy at King's College, London.</p>
                        </TxtImg>
                        <TxtImg
                            imgFirst={true}
                            split="narrowHalf"
                            imgSrc={data.hospital}
                            imgAlt=""
                        >
                            <h3>My role at Kettering General Hospital.</h3>
                            <p>Alongside running my practice, I've also been a Specialist Optometrist in the Ophthalmology Department at Kettering General Hospital since March 2020.</p>
                            <p>This role predominantly involves working alongside consultant ophthalmologists and examining the eyes of children and paediatric cases. I also work in glaucoma and diabetic clinics, performing the specialist examinations required for monitoring the conditions.</p>
                        </TxtImg>
                    </Console>
                </div>
            </section>
            <section>
                <TxtImg
                    imgFirst={true}
                    split="third"
                    imgSrc={data.research}
                    imgAlt="PhD thesis"
                >
                    <h2>Research</h2>
                    <p>My PhD has left me with a lasting interest in research. My Masters dissertation was based on a clinical case that I had seen in practice and went on to be published as a Continuing Education and Training (CET) article in one of the professional publications. I have since written several other such papers, gradually steering towards my field of expertise; retinal vascular health.</p>
                    <p>My thesis aimed to modernise the technique that optometrists use to record the appearance of the retinal blood vessels. The greatest success of this research project was to demonstrate a technique that could be easily performed in routine high street practice and suggests improvements can be made to the way in which optometrists work. I have been extremely fortunate in being able to present this research at a number of national and international conferences.</p>
                </TxtImg>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        sidestreet: file(relativePath: {eq: "IMG_7082.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
        earlyExperiences: file(relativePath: {eq: "early-experiences.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
        professional: file(relativePath: {eq: "professional-engagement.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
        hospital: file(relativePath: {eq: "christian-work.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
        research: file(relativePath: {eq: "research.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
        eye: file(relativePath: {eq: "eye.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    height: 900,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
    }
`

export default About
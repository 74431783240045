import * as React from "react"
import * as TitleStyles from "./Title.module.css"

function Title({ children }) {
    return (
        <div className={TitleStyles.container}>
            {children}
        </div>
    )
}

export default Title